import React from "react";
import Image from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

const CompanyPage = ({ data }) => {
	return (
		<Layout pageName="company">
			<SEO
				title="Blue Medical Company Information"
				description="The company was founded in 2005 and is one of the leading Swiss medical contract packaging companies."
			/>
			<Container sectionName="company-hero">
				<Image
					fluid={data.companyHeader.childImageSharp.fluid}
					alt="The Matterhorn, one of the most impressive mountains of the Swiss alps."
				/>
			</Container>
			<Intro className="company-intro">
				<h1 className="mono">Swiss values are part of our DNA</h1>
				<p className="company-intro__copy--big">
					Founded in 2005 we are celebrating our fifteenth birthday
					this year. Since its foundation Blue Medical has developed
					into one of the leading Swiss medical contract packaging
					companies, providing services to world market leaders.
				</p>
				<p className="company-intro__copy--small">
					Well-trained skilled and professional employees are the key
					to success. This has always been our foundation to
					continuously improve our expertise.
				</p>
			</Intro>

			<Container sectionName="positions">
				<div className="positions__heading" id="career">
					<h2>
						Become part of the Blue Medical team and discover
						vacancies.
					</h2>
				</div>
				<div className="positions__details-wrapper">
					<p className="details-intro">
						Success is never an individual achievement – it is
						always a team effort. A motivating working environment
						and a direct feedback culture offer the ideal conditions
						for this. Talent comes before hierarchy; individual
						skills, expertise and the drive to make a difference are
						more important. Hierarchy is not the primary criteria;
						individual skills, expertise and the will to make a
						difference prevail. We are all addressed by our first
						names, transparency and authenticity is the rule up to
						the management level. Discover the current open
						positions and become part of the team.
					</p>

					{/* <div className="position__detail temp-color">
						<h2>Account Manager (m/w/d)</h2>
						<span className="mono">
							Permanent, full-time · Burgdorf
						</span>
						<p className="position__description temp-color">
							One short sentence of job desctription as the
							interface between the client and the team, you are
							responsible to manage projects from inception to
							execution.
						</p>
						<div className="link-wrapper ">
							<ArrowSmallEx />
							<Link
								to="/"
								style={{
									color: "#1FD9E8"
								}}
							>
								Apply on jobs.ch
							</Link>
						</div>
					</div> */}
					<div className="position__detail">
						<h2>Currently no open positions</h2>

						<p className="position__description">
							We are always looking for skilled and enthusiastic
							people in all areas of our business. See below for
							any current job openings.
						</p>
					</div>
				</div>
			</Container>
		</Layout>
	);
};

export default CompanyPage;

export const pageQuery = graphql`
	query {
		companyHeader: file(
			relativePath: { eq: "company/BM_Company-Header_2928x720.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 1464) {
					...GatsbyImageSharpFluid
				}
			}
		}

		companyCleanroom: file(
			relativePath: { eq: "company/BM_Company_Cleanroom_2184x1032.jpg" }
		) {
			...fluidImage
		}

		companyManagement: file(
			relativePath: { eq: "company/BM_Company-Management_2184x1032.jpg" }
		) {
			...fluidImage
		}
		companyMountains: file(
			relativePath: { eq: "company/BM_Company-Mountains_1968x720.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 984) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
